import styled from "@emotion/styled"
import React from "react"
import { colors, device } from "./layout/GlobalStyles"
import { StaticImage } from "gatsby-plugin-image"
import twitter from "../images/logos/twitter.svg"
import facebook from "../images/logos/facebook.svg"
import instagram from "../images/logos/instagram.svg"
import linkedin from "../images/logos/linkedin.svg"
import youtubeNew from "../images/logos/youtubeNew.svg"
import foundationWhite from "../images/logos/logo-foundation-white.svg"
import churchWhite from "../images/logos/logo-church-white.svg"
import guideStar from "../images/logos/guidestar.svg"
import { Link } from "gatsby"

const StyledFooter = styled.footer`
  background: ${colors.primary3};
  padding: 72px 0px;
  a {
    color: ${colors.white};
    &:hover {
      text-decoration: underline;
    }
  }
  p {
    color: ${colors.white};
  }
  color: ${colors.white};
  @media ${device.tablet} {
    padding: 64px 0px;
    .address-container {
      display: flex;
      width: 100%;
      justify-content: space-between;
    }
    .resources-container {
      display: flex;
      width: 68.5%;
      justify-content: space-between;
    }
  }
  @media ${device.laptop} {
    padding: 100px 0px;
    .main-container {
      display: flex;
      > div:first-of-type {
        margin-right: 85.3px;
      }
      > div:last-of-type {
        width: 25%;
        p:first-of-type {
          font-size: 16px;
          letter-spacing: -0.5px;
          line-height: 22px;
        }
      }
    }
  }
`

const FirstPanel = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  border-bottom: 1px solid ${colors.secondary202};
  a {
    color: ${colors.white};
  }
  p {
    text-align: center;
    font-weight: 500;
    font-size: 16px;
    letter-spacing: -0.5px;
    line-height: 27px;
    padding-bottom: 10px;
  }
  img {
    width: 74px;
    height: 74px;
  }
  @media ${device.tablet} {
    flex-direction: row;
    p {
      margin-left: 2em;
      text-align: left;
      width: 589px;
    }
  }
`

const Address = styled.div`
  p:first-of-type {
    margin-top: 20px;
    padding: 0.5em 0em 0em;
    margin-bottom: -0.75em;
    font-weight: 800;
    font-size: 16px;
    color: ${colors.secondary202};
  }
  p:last-of-type {
    font-weight: 500;
    font-size: 16px;
    letter-spacing: -0.5px;
    line-height: 27px;
    padding: 0em 0em 0em;
    margin: 0em;

    color: ${colors.white};
  }
  @media ${device.laptop} {
    max-width: 210px;
    p:last-of-type {
      padding: 0.75em 0em 0em;
      margin: 0em;
    }
  }
`

const Resources = styled.div`
  p:first-of-type {
    margin-top: 20px;
    margin-bottom: -0.65em;
    font-weight: 800;
    font-size: 16px;
    letter-spacing: -0.5px;
    line-height: 27px;
  }
  P:last-of-type {
    font-weight: 500;
    font-size: 16px;
    letter-spacing: -0.5px;
    line-height: 27px;
    margin-bottom: 0em;
    padding-bottom: 0em;
  }
`

const Socials = styled.div`
  padding: 0em 0em 2em;
  p {
    margin-top: 35px;
    font-weight: 900;
    font-size: 20px;
    padding-bottom: 1em;
  }
  p:last-of-type {
    font-weight: 600;
    text-align: center;
    font-size: 12px;
    letter-spacing: -0.38px;
    line-height: 16px;
    padding-top: 1em;
  }
  .social-media {
    align-items: center;
    display: flex;
    padding-bottom: 2.24em;
    justify-content: space-between;
    border-bottom: 1px solid ${colors.secondary202};
  }
  .mlife {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 1em;
    p {
    }
    .plus img {
      width: 21px;
      height: 21px;
    }
    img {
      width: 98.8px;
      height: auto;
    }
  }
  @media ${device.tablet} and (max-width: 1020px) and (orientation: portrait) {
    .mlife {
      justify-content: space-around;
      padding-top: 20px;
    }
    p:last-of-type {
      text-align: center;
    }
  }
  @media ${device.laptop} {
    padding: 0em 0em 0em;
    .mlife {
      margin-top: 28px;
      padding-bottom: 0px;
    }
    p:last-of-type {
      margin-top: 18px;
      text-align: left;
      padding-right: 1em;
    }
    position: relative;
    bottom: 1.25em;
  }
`

export const Footer = () => {
  return (
    <StyledFooter>
      <div className="main-container container">
        <div className="container">
          <FirstPanel>
            <a
              href="https://www.guidestar.org/profile/shared/e4aa0071-1a9a-494e-83a0-b749aa31cfc4"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src="https://widgets.guidestar.org/TransparencySeal/9590010" />
            </a>
            <p>
              MLIFE is a US 501(c)3 faith-based organization. EIN: 47-4678443.
              For US citizens and residents: To claim the tax deductibility of
              this gift in the United States, please retain the acknowledgment
              receipt for your record.
            </p>
          </FirstPanel>
          <div className="address-container">
            <Address>
              <div>
                <p>San Francisco</p>
                <p>
                  P.O. Box 40609 <br />
                  San Francisco, CA 94140 <br />
                  +1-650-713-3057
                </p>
              </div>
            </Address>
            <Address>
              <div>
                <p>Nairobi</p>
                <p>
                  P.O. Box 17652-GPO <br />
                  Nairobi, Kenya 00100 <br />
                  +254 789 133 057
                </p>
              </div>
            </Address>
            <Address>
              <div>
                <p>Kilifi</p>
                <p>
                  P.O. Box 253 <br />
                  Kilifi, Kenya 80108 <br />
                  +254 781 133 057
                </p>
              </div>
            </Address>
          </div>

          <div className="resources-container">
            <Resources>
              <p>Prayer & Resource Line</p>
              <p>
                +1-833-LV-JESUS
                <br />
                (+1-833-585-3787)
              </p>
            </Resources>
            <Resources>
              <p>Prayer & Resource Line </p>
              <p>
                {" "}
                +254 715-853-787
                <br />
                (+254-71-LV-JESUS)
              </p>
            </Resources>
          </div>
        </div>
        <div>
          <Socials>
            <p>Get social with us, share the love & join the conversation.</p>
            <div className="social-media">
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.facebook.com/mlifefoundation"
              >
                <img src={facebook} alt="facebook logo" />
              </a>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.instagram.com/mlifefoundation/"
              >
                <img src={instagram} alt="instagram logo" />
              </a>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.linkedin.com/company/mlifefoundation/"
              >
                <img src={linkedin} alt="linkedin logo" />
              </a>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://twitter.com/mlifefoundation"
              >
                <img src={twitter} alt="twitter logo" />
              </a>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.youtube.com/channel/UCui-ec9MIcb5BSBHSbTWcdQ"
              >
                <img src={youtubeNew} alt="youtube logo" />
              </a>
            </div>
            <div className="mlife">
              <img src={foundationWhite} alt="mlife foundation logo" />
              <StaticImage
                className="plus"
                src="../images/icons/plus-white.svg"
                placeholder="none"
                quality={100}
                alt="plus sign"
              />
              <img src={churchWhite} alt="mlife church logo" />
            </div>
            <p>
              MLIFE Foundation © All rights reserved. <br />
              <Link to="/privacy-policy/">Privacy Policy</Link> |{" "}
              <Link to="/terms-of-use/">Terms of Use</Link>
            </p>
          </Socials>
        </div>
      </div>
    </StyledFooter>
  )
}
