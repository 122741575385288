import { useState, createContext } from "react"
import React from "react"

export const siteContext = createContext()

export const SiteContextProvider = ({ children }) => {
  const [mobileActive, setMobileActive] = useState(false)
  const [desktopActive, setDesktopActive] = useState(false)
  const [categoryActive, setCategoryActive] = useState(false)
  const [categoryMenu, setCategoryMenu] = useState(false)
  const [toolTipMobile, setToolTipMobile] = useState(false)
  const [forceOpen, setForceOpen] = useState(false)
  return (
    <siteContext.Provider
      value={{
        forceOpen,
        setForceOpen,
        toolTipMobile,
        setToolTipMobile,
        categoryMenu,
        setCategoryMenu,
        categoryActive,
        setCategoryActive,
        mobileActive,
        setMobileActive,
        desktopActive,
        setDesktopActive,
      }}
    >
      {children}
    </siteContext.Provider>
  )
}
