import { Global, css } from "@emotion/react"
import React from "react"

export const colors = {
  primary1: "#E8F0EF",
  primary2: "#16675A",
  primary3: "#133832",
  greenExtraDark: "#16675A",
  secondary101: "#ECF2F7",
  secondary102: "#3E7AB1",
  secondary201: "#FFFAF1",
  secondary202: "#E3BF2A",
  secondary203: "#F4E5AA",
  secondary302: "#672E31",
  secondary403: "#861B1F",
  dark3: "#444444",
  white: "#FFFFFF",
  grey: "#4F4F4F",
  buttonRed: "#BF262C",
  buttonBrown: "#341719",
}
export const fonts = {
  primary: "Montserrat",
  secondary: "BioRhyme",
}

const size = {
  mobileS: "320px",
  mobileM: "375px",
  mobileL: "400px",
  tablet: "670px",
  laptop: "1120px",
  laptopL: "1360px",
  desktop: "1580px",
  desktopL: "1820px",
}

export const device = {
  mobileS: `(min-width: ${size.mobileS})`,
  mobileM: `(min-width: ${size.mobileM})`,
  mobileL: `(min-width: ${size.mobileL})`,
  tablet: `(min-width: ${size.tablet})`,
  laptopS: `(min-width: 840px) and (orientation:landscape)`,
  laptop: `(min-width: ${size.laptop}) and (orientation:landscape)`,
  laptopL: `(min-width: ${size.laptopL})`,
  desktop: `(min-width: ${size.desktop})`,
  desktopL: `(min-width: ${size.desktopL})`,
}

export const GlobalStyles = () => {
  return (
    <Global
      styles={css`
        html {
          box-sizing: border-box;
          font-size: 100%;
          overflow-x: hidden;
          height: -webkit-fill-available;
          button {
            cursor: pointer;
          }
        }

        * {
          outline: none;
        }
        body {
          min-height: 100vh; /* fall-back */
          min-height: -moz-available;
          min-height: -webkit-fill-available;
          min-height: fill-available;
          margin: 0em;
          -ms-text-size-adjust: 100%;
          -webkit-text-size-adjust: 100%;
          scroll-behavior: smooth;
          -webkit-font-smoothing: antialiased;
          -moz-osx-font-smoothing: grayscale;
          min-height: 100vh;
          /* mobile viewport bug fix */
          min-height: -webkit-fill-available;
        }
        button {
          outline: none !important;
        }

        a {
          text-decoration: none;
        }
        select:focus {
          outline: 2.5px solid ${colors.primary2}; /* oranges! yey */
          /*  */
          border-radius: 3px;
        }
        p {
          color: ${colors.dark3};
          font-size: 22px;
        }
        h2,
        h3,
        h4 {
          color: ${colors.primary2};
          font-family: ${fonts.secondary};
        }
        .container {
          max-width: 340px;
          margin: 0 auto;
        }
        input[type="time"]:focus {
          outline: 2.5px solid ${colors.primary2}; /* oranges! yey */
          /*  */

          border-radius: 3px;
        }
        .special {
          display: inline-block;
          font-weight: 700;
          text-decoration: underline;
          cursor: pointer;
          color: ${colors.primary2};
        }
        input[type="text"]:focus {
          outline: none;
          /*  */

          border-radius: 3px;
        }
        input[type="text"]:focus::placeholder,
        input[type="tel"]:focus::placeholder,
        input[type="email"]:focus::placeholder,
        textarea:focus::placeholder {
          color: ${colors.primary2};
        }
        input[type="email"]:focus {
          /* oranges! yey */
          /*  */

          border-radius: 3px;
        }
        .gl-modal {
          display: none;
        }
        input[type="tel"]:focus {
          outline: 2.5px solid ${colors.primary2}; /* oranges! yey */
          /*  */

          border-radius: 3px;
        }

        input[type="date"]:focus,
        input[type="text"]:focus {
          outline: 2.5px solid ${colors.primary2}; /* oranges! yey */
          /*  */

          border-radius: 3px;
        }
        input[type="date"]::-webkit-calendar-picker-indicator {
          position: relative;
          right: 0.6em;
          top: 0.32em;
          height: 30px;

          -webkit-appearance: none;
        }
        textarea {
          font-family: "inter";
        }
        p {
          font-weight: 400;
          font-family: ${fonts.primary};
          font-size: 18px;
          line-height: 32px;
          letter-spacing: -0.5625px;
        }
        textarea:focus {
          outline: 2px solid ${colors.primary2}; /* oranges! yey */
        }
        @media ${device.mobileL} {
          .container {
            max-width: 375px;
            margin: 0 auto;
          }
        }

        @media ${device.tablet} and (max-width: 1000px) and (orientation: portrait) {
          .container {
            margin: 0 auto;
            max-width: 650px;
          }
        }
        @media ${device.tablet} and (orientation: landscape) {
          .container {
            margin: 0 auto;
            padding: 0em 1em;
            max-width: 720px;
          }
        }
        @media ${device.laptop} {
          p {
            font-size: 22px;
          }
          p {
            font-weight: 400;
            font-family: ${fonts.primary};
          }
          .container {
            margin: 0 auto;
            max-width: 1112px;
          }
        }
        @media ${device.laptopL} {
          .container {
            margin: 0 auto;
            max-width: 1110px;
          }
        }
        @media ${device.desktop} {
          .container {
            margin: 0 auto;
            max-width: 1110px;
          }
        }
      `}
    />
  )
}
