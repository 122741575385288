export const navMenu = [
  {
    name: "Purpose",
    url: "/purpose/",
    pageName: "Purpose",
    // subMenu: [
    //   {
    //     name: "Guiding Philosophy",
    //     url: "guiding-philosophy/",
    //   },
    //   {
    //     name: "What We do",
    //     url: "what-we-do",
    //   },
    //   {
    //     name: "Our Mission",
    //     url: "our-mission",
    //   },
    //   {
    //     name: "Vision",
    //     url: "vision",
    //   },
    //   {
    //     name: "Values",
    //     url: "values",
    //   },
    //   {
    //     name: "Glossary",
    //     url: "glossary",
    //   },
    //   {
    //     name: "Mwangi Mukami Story",
    //     url: "mwangi-mukami-story",
    //   },
    // ],
  },
  {
    name: "Programs",
    pageName: "Programs",
    mobilePath: "/programs/",
    subMenu: [
      // {
      //   name: "Overview",
      //   url: "/programs#overview/",
      // },
      {
        name: "Overview",
        pageName: "Programs",
        url: "/programs/",
      },
      {
        name: "LEAP",
        pageName: "Programs",
        url: "/programs/leap/",
      },
      {
        name: "MLOVE",
        pageName: "Programs",
        url: "/programs/mlove/",
      },
      {
        name: "Ecclesia",
        pageName: "Programs",
        url: "/programs/ecclesia/",
      },
      {
        name: "Spaces of Grace",
        pageName: "Programs",
        url: "/programs/spaces-of-grace/",
      },
      {
        name: "RAPID",
        pageName: "Programs",
        url: "/programs/rapid/",
      },
    ],
  },
  {
    name: "People",
    url: "/people/",
    pageName: "People",
  },
  {
    name: "News & Events",
    url: "/news-events/",
    pageName: "Blog",
  },
  {
    name: "Church",
    url: "/church/",
    // subMenu: [
    //   {
    //     name: "Purpose",
    //     url: "/purpose",
    //   },
    //   {
    //     name: "Purpose",
    //     url: "/purpose",
    //   },
    // ],
  },
]
