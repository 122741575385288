import styled from "@emotion/styled"
import React from "react"
import { device } from "./layout/GlobalStyles"
import { useState, useEffect, useCallback } from "react"
import { graphql, useStaticQuery } from "gatsby"
import { render } from "react-dom"

const AlertContainerTwo = styled.div`
  background: #fffaf1;
  z-index: 0;
  display: block;
  width: 100vw;
  margin-bottom: -72px;
  height: 72px;
  position: absolute;
  top: 0;
`

const AlertContainer = styled.div`
  background: ${({ alertActive }) => (alertActive ? "#Bf262c" : "#fffaf1")};
  display: ${({ alertActive }) => (alertActive ? "block" : "none")};
  z-index: 20;
  width: 100vw;
  position: absolute;
  top: 0;
  position: relative;
  .text-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 72px;
    transition: height 0.2s ease-in-out;
    position: relative;
  }
  a {
    text-align: center;
    position: absolute;
    z-index: 300;
  }
  p {
    display: ${({ alertActive }) => (alertActive ? "block" : "none")};
    color: ${({ alertActive }) => (alertActive ? "#fffaf1" : "#fff")};
    font-family: BioRhyme;
    font-size: 18px;
    letter-spacing: -0.69px;
    line-height: 28px;

    b {
      font-weight: 800;
    }
    span {
      cursor: pointer;
      text-decoration: underline;
    }
    /* a {
      position: relative;
      z-index: 2000000;
      color: #ffffff;
      text-decoration: underline;
    } */
  }

  .fade-text {
    animation: animationTwo 10s ease-in-out infinite;
    animation-delay: 5s;
  }
  .no-fade-text {
    animation: animationTwo 10s ease-in-out infinite;
  }
  .single-fade-text {
    animation: animationOne 5s ease-in-out infinite;
  }
  @keyframes animationOne {
    0% {
      opacity: 0;
    }
    50% {
      opacity: 1;
    }
    75% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
  @keyframes animationTwo {
    0% {
      opacity: 0;
    }
    50% {
      opacity: 0;
    }
    75% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
  @media ${device.laptop} {
    position: fixed;
    top: 0%;
    font-size: 22px;

    .text-container {
      height: ${({ shrink }) => (shrink ? "48px" : "72px")};
    }
  }

  //define animation that makes text appear and ndisappear
`

export const Alert = ({ alert }) => {
  const debounce = (func, ms) => {
    let timer
    return (...args) => {
      if (!timer) {
        timer = setTimeout(() => {
          timer = undefined
          func.apply(this, args)
        }, ms)
      }
    }
  }

  const [show, setShow] = React.useState(false)
  const [shrink, setShrink] = React.useState(false)
  const isSSR = typeof window === "undefined"
  const [position, setPosition] = useState(isSSR ? 0 : window.scrollY)

  const debounceMs = 200

  const onScroll = useCallback(
    debounce(() => setPosition(window.scrollY), 300),
    [debounceMs, setPosition]
  )

  useEffect(() => {
    if (isSSR) return
    window.addEventListener("scroll", onScroll)
    if (position > 100) {
      setShrink(true)
    } else {
      setShrink(false)
    }
    return () => {
      window.removeEventListener("scroll", onScroll)
    }
  }, [position])

  console.log(alert)

  // const renderCopy = () => {
  //   if (alert.node.frontmatter.prompts.length == 1) {
  //     return (
  //       <div className="text-container">
  //         <a
  //           href={alert.node.frontmatter.alert_link}
  //           target="__blank"
  //           rel="noopener noreferrer"
  //         >
  //           <p className="single-fade-text">
  //             {alert.node.frontmatter.prompts[0].blurb}
  //           </p>
  //         </a>
  //       </div>
  //     )
  //   }
  // }
  // if (alert.node.frontmatter.prompts.length == 2) {
  //   return (
  //     <div className="text-container">
  //       <a
  //         href={alert.node.frontmatter.alert_link}
  //         target="__blank"
  //         rel="noopener noreferrer"
  //       >
  //         <p className="fade-text">{alert.node.frontmatter.prompts[1].blurb}</p>
  //       </a>
  //       <a
  //         href={alert.node.frontmatter.alert_link}
  //         target="__blank"
  //         rel="noopener noreferrer"
  //       >
  //         <p className="no-fade-text"></p>
  //       </a>
  //     </div>
  //   )
  // }

  return (
    <>
      <AlertContainer alertActive={alert ? true : false} shrink={shrink}>
        {!alert.node.frontmatter.prompts && <></>}
        {alert.node.frontmatter.prompts.length == 1 && (
          <div className="text-container">
            <a
              href={alert.node.frontmatter.alert_link}
              target="__blank"
              rel="noopener noreferrer"
            >
              <p className="single-fade-text">
                {alert.node.frontmatter.prompts[0].blurb}
              </p>
            </a>
          </div>
        )}
        {alert.node.frontmatter.prompts.length == 2 && (
          <div className="text-container">
            <a
              href={alert.node.frontmatter.alert_link}
              target="__blank"
              rel="noopener noreferrer"
            >
              <p className="fade-text">
                {alert.node.frontmatter.prompts[0].blurb}
              </p>
            </a>
            <a
              href={alert.node.frontmatter.alert_link}
              target="__blank"
              rel="noopener noreferrer"
            >
              <p className="no-fade-text">
                {alert.node.frontmatter.prompts[1].blurb}
              </p>
            </a>
          </div>
        )}
      </AlertContainer>
      <AlertContainerTwo
        alertActive={alert ? true : false}
        shrink={shrink}
      ></AlertContainerTwo>
    </>
  )
}
