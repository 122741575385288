import { cloneElement } from "react"
import { SiteContextProvider } from "./src/layout/SiteContextProvider"
import { DefaultLayout } from "./src/layout/DefaultLayout"
import React from "react"

// eslint-disable-next-line
export const wrapRootElement = ({ element }) => {
  return <SiteContextProvider>{element}</SiteContextProvider>
}

// eslint-disable-next-line
export const wrapPageElement = ({ element, props }) => {
  if (element.type.Layout === false) {
    return cloneElement(element, props)
  }
  const Layout = element.type.Layout ?? DefaultLayout
  return <Layout {...props}>{element}</Layout>
}
