import { colors, fonts, device } from "./GlobalStyles"
import styled from "@emotion/styled"

export const Button = styled.button`
  background: ${({ color }) =>
    (color === "green" && `${colors.primary2}`) ||
    (color === "yellow" && `${colors.secondary202}`) ||
    (color === "clear" && `none`)};
  color: ${({ color }) =>
    (color === "green" && `${colors.white}`) ||
    (color === "yellow" && `#341719`) ||
    (color === "clear" && `${colors.secondary302}`)};
  border: ${({ color }) =>
    (color === "green" && `2px solid ${colors.primary2}`) ||
    (color === "yellow" && `2px solid ${colors.secondary202}`) ||
    (color === "clear" && `2px solid ${colors.secondary202}`)};
  font-weight: ${({ color }) =>
    (color === "green" && `900`) ||
    (color === "yellow" && `900`) ||
    (color === "clear" && `500`)};
  font-size: 16px;
  min-width: ${({ color }) => color === "clear" && "160px"};
  padding: ${({ color }) =>
    (color === "green" && `1em 1.5em`) ||
    (color === "yellow" && `1em 1.5em`) ||
    (color === "clear" && `1em 1.5em`)};
  font-family: ${fonts.primary};
  border-radius: 32px;
  box-shadow: 0 8px 20px 0 rgba(22, 103, 90, 0.2);
  transition: background-color 0.4s ease-out;
  transition: color 0.2s ease-in;
  &:hover {
    color: ${({ color }) =>
      (color === "green" && `${colors.primary2}`) ||
      (color === "yellow" && `${colors.white}`) ||
      (color === "clear" && `${colors.buttonRed}`)};
    background: ${({ color, backgroundFill }) =>
      (color === "green" && `none`) ||
      (color === "yellow" && `${colors.secondary302}`)};
    border: ${({ color, backgroundFill }) =>
      (color === "yellow" && `2px solid ${colors.secondary302}`) ||
      (color === "clear" && `2px solid ${colors.secondary302}`)};
    transition: box-shadow 0.2s ease-out;
    transition: font-weight 0.2s ease-out;
    box-shadow: 0 4px 10px 0 rgba(22, 103, 90, 0.2);
  }
  :focus {
    color: ${({ color }) =>
      (color === "yellow" && `${colors.white}`) ||
      (color === "green" && `${colors.primary2}`) ||
      (color === "clear" && `${colors.buttonBrown}`)};
    background: ${({ color, backgroundFill }) =>
      (color === "green" && `none`) ||
      (color === "yellow" && `${colors.buttonBrown}`)};
    border: ${({ color, backgroundFill }) =>
      (color === "yellow" && `2px solid ${colors.buttonBrown}`) ||
      (color === "clear" && `2px solid ${colors.ButtonBrown}`)};
  }
  @media ${device.laptop} {
    letter-spacing: -0.69px;
    line-height: 26px;
    font-size: 18px;
    padding: ${({ color }) =>
      (color === "green" && `0.75em 1.25em`) ||
      (color === "yellow" && `.92em 2.25em`)};
  }
`
