import React, { useContext } from "react"
import { GlobalStyles } from "../components/layout/GlobalStyles"
import { Nav } from "../components/nav/Nav"
import { Alert } from "../components/Alert"
import { Footer } from "../components/Footer"
import { siteContext } from "./SiteContextProvider"
import styled from "@emotion/styled"
import { device } from "../components/layout/GlobalStyles"
import { graphql, useStaticQuery } from "gatsby"

const Container = styled.div`
  @media ${device.laptop} {
    position: relative;

    top: ${({ alertActive }) => (alertActive ? "72px" : "0px")};
  }
`

export const DefaultLayout = ({ children, data }) => {
  const { setDesktopActive } = useContext(siteContext)

  let alert = useStaticQuery(graphql`
    query {
      allMarkdownRemark(
        filter: {
          frontmatter: { active: { eq: true } }
          fields: { sourceName: { eq: "alert-banner" } }
        }
        sort: { fields: frontmatter___date, order: DESC }
      ) {
        edges {
          node {
            id
            frontmatter {
              active
              title
              date
              expiration_date
              alert_link
              prompts {
                blurb
              }
            }
          }
        }
      }
    }
  `)

  const expiration_normalizer = items => {
    let ads_array = []

    items.forEach(item => {
      console.log(item)
      if (
        !item.node.frontmatter.expiration_date ||
        item.node.frontmatter.expiration_date == ""
      ) {
        ads_array.push(item)
      } else {
        let expirationDate = new Date(item.node.frontmatter.expiration_date)
        let currentDate = new Date()
        if (expirationDate > currentDate) {
          ads_array.push(item)
        }
      }
    })
    return ads_array
  }

  console.log()

  const non_expired_alerts = expiration_normalizer(
    alert.allMarkdownRemark.edges
  )

  let final_alert = non_expired_alerts[0]

  return (
    <>
      {final_alert && <Alert alert={final_alert} />}

      <Container alertActive={final_alert ? true : false} id="layout">
        <Nav />
        <GlobalStyles />
        <div onMouseEnter={() => setDesktopActive(false)}>
          {children}
          <Footer />
        </div>
      </Container>
    </>
  )
}
